import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home'; // ホーム
import InfoIcon from '@mui/icons-material/Info'; // 音研21の紹介
import GroupIcon from '@mui/icons-material/Group'; // 会員紹介
import HistoryIcon from '@mui/icons-material/History'; // 今までの活動・公演
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'; // フォトギャラリー
import MessageIcon from '@mui/icons-material/Message'; // 掲示板
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

type MyFunctionType = (page: number) => void;

interface HeaderProps {
    sections: ReadonlyArray<{
        title: string;
        url: string;
    }>;
    onNavigatorClick: MyFunctionType
}

export default function Onken21Header(props: HeaderProps) {
    const {/*sections,*/ onNavigatorClick} = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        //console.log(`Onken21Header:handleChange newValue=${newValue}`);
        setValue(newValue);
        onNavigatorClick(newValue);
    };

    const tabsItemWidth = 130;

    return (
        <Grid container sx={{minWidth: 1000}}>
            <Grid item xs={12} sm={12} md={12} >
                <Tabs
                    sx={{
                        margin: 'auto',
                        '& .MuiTabs-flexContainer': {flexWrap: 'wrap'},
                    }}
                    value={value}
                    variant = "fullWidth"
                    onChange={handleChange}
                    aria-label="icon label tabs"
                    //indicatorColor="secondary"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#00CC00',
                            height: '10px', // '10px',
                        }
                    }}
                >
                    <Tab sx={{width: tabsItemWidth}} icon={<HomeIcon/>} label="ホーム"/>
                    <Tab sx={{width: tabsItemWidth}} icon={<InfoIcon/>} label="音研21の紹介"/>
                    <Tab sx={{width: tabsItemWidth}} icon={<HistoryIcon/>} label="今までの活動・公演"/>
                    <Tab sx={{width: tabsItemWidth}} icon={<GroupIcon/>} label="会員の紹介"/>
                    <Tab sx={{width: tabsItemWidth}} icon={<PhotoLibraryIcon/>} label="フォトギャラリー"/>
                    <Tab sx={{width: tabsItemWidth}} icon={<MessageIcon/>} label="掲示板"/>
                </Tabs>
            </Grid>
        </Grid>
    );

}
