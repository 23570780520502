import React from "react";
import Box from "@mui/material/Box";
import EventPost from "./EventPost";
import {Grid, styled} from "@mui/material";
import Paper from "@mui/material/Paper";
import MainContent from "./MainContent";
import MainFeaturedPost from "./MainFeaturedPost";
import useMediaQuery from "@mui/material/useMediaQuery";
import {/*sidebar,*/ nextEventProps, pastEventProps, homeImagesProps, HomeImageTitlePositionProps} from "./data/home_contents";
import {globalTheme} from "./App";
import Typography from "@mui/material/Typography";

const noteStyle = {color: "#ff69b4", fontWeight: 'bold'};

export default function Onken21Home() {
    const numberOfImages = homeImagesProps.length;
    const currentDateString4Seed = new Date().getTime();
    //console.log(`currentDateString4Seed: ${currentDateString4Seed}`);
    const randomNumberGen = require('random-seed').create(`${currentDateString4Seed}`);
    const imageIndex = randomNumberGen(numberOfImages);
    //const imageIndex = 50;
    //console.log(`imageIndex: ${imageIndex}`);
    const homeImagesProp = homeImagesProps[imageIndex];
    const backgroundImage = homeImagesProp.imageUrl;
    const homeTitlePosProp = new HomeImageTitlePositionProps(homeImagesProp.low_position);

    //const xs_matches = useMediaQuery(globalTheme.breakpoints.up('xs'));
    const sm_matches = useMediaQuery(globalTheme.breakpoints.up('sm'));
    const md_matches = useMediaQuery(globalTheme.breakpoints.up('md'));
    //console.log(`xs_matches: ${xs_matches}, sm_matches: ${sm_matches}, md_matches: ${md_matches}`);
    const offset = md_matches
        ? homeImagesProp.adjustOffset_md
        : (sm_matches ? homeImagesProp.adjustOffset_sm : homeImagesProp.adjustOffset_xs);
    const height = md_matches ? 740 : (sm_matches ? 720 : 250);

    const Background = styled(Box)({
        position: 'absolute',
        left: 0,
        right: 0,
        top: offset,
        bottom: 0,
        height: height - offset,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${backgroundImage})`,
        zIndex: -2,
    });

    return (
        <>
            <Paper elevation={0} sx={{backgroundColor: 'transparent'}}>

                <MainFeaturedPost low_position={homeTitlePosProp.low_position} />

                <Background/>

                <Grid container spacing={4} sx={{pl: 2, pr: 2}}>
                    <EventPost post={nextEventProps.post}/>
                    <EventPost post={pastEventProps.post}/>
                </Grid>
            </Paper>

            <Grid container spacing={5} sx={{mt: 3}}>

                <MainContent title="更新内容"/>

                <Grid item xs={12} md={4}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <img src='old_contents/vaiolin player.gif' alt=''/>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" sx={{p: 0.2}}><span style={noteStyle}>♫</span> あなたは <img src="https://www.onken21.net/cgi-bin/daycount/daycount.cgi?gif" alt={''}/> 番目の訪問者です。</Typography>
                            <Typography variant="subtitle1" sx={{p: 0.2}}><span style={noteStyle}>♫</span> 本日のアクセス数　<img src="https://www.onken21.net/cgi-bin/daycount/daycount.cgi?today" alt={''}/></Typography>
                            <Typography variant="subtitle1" sx={{p: 0.2}}><span style={noteStyle}>♫</span> 昨日のアクセス数　<img src="https://www.onken21.net/cgi-bin/daycount/daycount.cgi?yes" alt={''}/></Typography>
                            <Typography variant="subtitle1" sx={{mt: 2, fontSize: 16, color: '#005500', fontWeight: 'regular'}}>管理人 onken21.web.master@gmail.com </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
}
