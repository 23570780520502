import { Type } from 'class-transformer';

export class PhotoInfo {
    src: string;
    caption: string;
    description: string;
    key?: string;

    constructor(src: string, caption: string, description: string) {
        this.src = src;
        this.caption = caption;
        this.description = description;
    }
}

export class EventPhotoProps {
    event_id: string;
    event_date: string;
    event_place: string;
    work_title: string;

    @Type(() => PhotoInfo)
    photo: PhotoInfo[];

    constructor(event_id: string,
                event_date: string,
                event_place: string,
                work_title: string,
                photo: Array<PhotoInfo>,
    )
    {
        this.event_id = event_id;
        this.event_date = event_date;
        this.event_place = event_place;
        this.work_title = work_title;
        this.photo = photo;
    }
}