import * as React from 'react';
import {useState} from "react";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';

import Onken21OperaEvent from "./Onken21OperaEvent";
import Onken21ConcertEvent from "./Onken21ConcertEvent";
import {EventType, past_events} from "./data/onken21_history_index";
import {getOpera, getConcert} from "./data_event/onken21_event_detail";

import "./hideScrollbar.css";
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

function LeftArrow() {
    const {isFirstItemVisible, scrollPrev} = React.useContext(VisibilityContext);
    return (
        <Button disabled={isFirstItemVisible} sx={{height: 76}} onClick={() => scrollPrev()}>
            <ArrowCircleLeftIcon sx={{width: 50, height: 50}}/>
        </Button>
    );
}

function RightArrow() {
    const {isLastItemVisible, scrollNext} = React.useContext(VisibilityContext);
    return (
        <Button disabled={isLastItemVisible} sx={{height: 76}} onClick={() => scrollNext()}>
            <ArrowCircleRightIcon sx={{width: 50, height: 50}}/>
        </Button>
    );
}

const getColor = (eventType: EventType) => {
    if (eventType === EventType.Opera) {
        return '#F8FFF8'; //'lightcyan';
    } else if (eventType === EventType.Operetta) {
        return '#F8FFF8'; //'cornsilk';
    }
    return '#F8FFF8'; //'antiquewhite'; // Concert
};

const sidebar = past_events;
// ↓「今までの活動・公演」を開いた時に、最初に表示されるページのインデックス。
const initialEventId = past_events.archives[0].event_id;

export default function Onken21History() {

    const [selectedEventId, setSelectedEventId] = useState<string>(initialEventId);
    let eventOperaProp = getOpera(selectedEventId);
    let eventConcertProp = getConcert(selectedEventId);

    const handleItemClick = (event: React.MouseEvent<HTMLElement>, event_id: string) => {
        //console.log(`event_id = ${event_id}`);
        setSelectedEventId(event_id);
    };

    return (

        <Paper sx={{margin: 'auto', overflow: 'hidden'}}>

            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}
            >
                <Toolbar>

                    <Grid container sx={{mt: 0, mb: 4}}>

                        <Grid item xs={12} sm={12} md={12} sx={{height: 46, backgroundColor: 'rgba(245,245,245,255)'}}>
                            <Typography sx={{fontSize: 20, ml: 1, mt: 2, fontWeight: 'bold'}} color="text.secondary">
                                今までの活動・公演
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} sx={{width: 350, height: 76, mt: 1, backgroundColor: 'transparent'}}>
                            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                                {sidebar.archives.map((archive) => {
                                    return (
                                        <Button
                                            key={archive.event_id}
                                            sx={{
                                                width: 236,
                                                height: 60,
                                                backgroundColor: getColor(archive.event_type),
                                                ml: 0.5, mt: 1, mr: 0.5, mb: 1,
                                                borderRadius: 2,
                                                boxShadow: 2
                                            }}
                                            onClick={(e) => handleItemClick(e, archive.event_id)}
                                        >
                                            <Typography variant="subtitle1">
                                                <Grid>
                                                    <Grid>{archive.event_date}</Grid>
                                                    <Grid>{archive.title}</Grid>
                                                </Grid>
                                            </Typography>
                                        </Button>
                                    )
                                })}
                            </ScrollMenu>
                        </Grid>

                        {(eventOperaProp !== undefined) ?
                            <Grid item xs={12} sm={12} md={12} sx={{mt: 0.5}}>
                                <Onken21OperaEvent
                                    event_id={eventOperaProp.event_id}
                                    event_date={eventOperaProp.event_date}
                                    formatted_date={eventOperaProp.formatted_date}
                                    event_time1={eventOperaProp.event_time1}
                                    event_time2={eventOperaProp.event_time2}
                                    event_place={eventOperaProp.event_place}
                                    event_station={eventOperaProp.event_station}
                                    composer_name={eventOperaProp.composer_name}
                                    work_title={eventOperaProp.work_title}
                                    number_of_act={eventOperaProp.number_of_act}
                                    flyer_large_file={eventOperaProp.flyer_large_file}
                                    flyer_small_file={eventOperaProp.flyer_small_file}
                                    stuff={eventOperaProp.stuff}
                                    cast={eventOperaProp.cast}
                                    ext_links={eventOperaProp.ext_links}
                                />
                            </Grid> : <div/>
                        }
                        {(eventConcertProp !== undefined) ?
                            <Grid item xs={12} sm={12} md={12} sx={{mt: 0.5}}>
                                <Onken21ConcertEvent
                                    event_id={eventConcertProp.event_id}
                                    event_date={eventConcertProp.event_date}
                                    formatted_date={eventConcertProp.formatted_date}
                                    event_time1={eventConcertProp.event_time1}
                                    event_time2={eventConcertProp.event_time2}
                                    event_place={eventConcertProp.event_place}
                                    event_station={eventConcertProp.event_station}
                                    composer_name={eventConcertProp.composer_name}
                                    work_title={eventConcertProp.work_title}
                                    number_of_act={eventConcertProp.number_of_act}
                                    flyer_large_file={eventConcertProp.flyer_large_file}
                                    flyer_small_file={eventConcertProp.flyer_small_file}
                                    cast={eventConcertProp.cast}
                                    ext_links={eventConcertProp.ext_links}
                                />
                            </Grid> : <div/>
                        }
                    </Grid>

                </Toolbar>
            </AppBar>

        </Paper>

    );

}
