import { Type } from 'class-transformer';

export class TitleName {
    title: string;
    sub_title?: string;
    name: string;
    alt_name?: string;

    /*constructor(title: string, name: string) {
        this.title = title;
        this.name = name;
    }*/
    constructor(title: string, sub_title: string, name: string, alt_name: string) {
        this.title = title;
        this.sub_title = sub_title;
        this.name = name;
        this.alt_name = alt_name;
    }
}

export class ExternalLink {
    link_title: string;
    link_url: string;
    link_embedded_url: string;
    link_embedded_width: number;
    link_embedded_height: number;

    constructor(link_title: string, link_url: string, link_embedded_url: string, link_embedded_width: number, link_embedded_height: number) {
        this.link_title = link_title;
        this.link_url = link_url;
        this.link_embedded_url = link_embedded_url;
        this.link_embedded_width = link_embedded_width;
        this.link_embedded_height = link_embedded_height;
    }
}

export class EventOperaProps {
    event_id: string;
    event_date: string;
    formatted_date: string;
    event_time1: string;
    event_time2: string;
    event_place: string;
    event_station: string;
    composer_name: string;
    work_title: string;
    number_of_act: string;
    flyer_large_file: string;
    flyer_small_file: string;
    ext_links: Array<ExternalLink>;

    @Type(() => TitleName)
    stuff: TitleName[];

    @Type(() => TitleName)
    cast: TitleName[];

    constructor(event_id: string,
                event_date: string,
                formatted_date: string,
                event_time1: string,
                event_time2: string,
                event_place: string,
                event_station: string,
                composer_name: string,
                work_title: string,
                number_of_act: string,
                flyer_large_file: string,
                flyer_small_file: string,
                stuff: Array<TitleName>,
                cast: Array<TitleName>,
                ext_links: Array<ExternalLink>
    )
    {
        this.event_id = event_id;
        this.event_date = event_date;
        this.formatted_date = formatted_date; //event_date;
        this.event_time1 = event_time1; // event_date;
        this.event_time2 = event_time2; // event_date;
        this.event_place = event_place;
        this.event_station = event_station;
        this.composer_name = composer_name;
        this.work_title = work_title;
        this.number_of_act = number_of_act;
        this.flyer_large_file = flyer_large_file;
        this.flyer_small_file = flyer_small_file;
        this.stuff = stuff;
        this.cast = cast;
        this.ext_links = ext_links;
    }
}