export interface MemberProfile {
    profile: {
        name_jp: string,
        name_en: string,
        part: string,
        photoImagePath: string,
        resume: string[]
    }
}

export const allMember: MemberProfile[] = [
    {
        profile: {
            name_jp: '吉田 澄子',
            name_en: 'Sumiko Yoshida',
            part: 'Soprano',
            photoImagePath: 'old_contents/images/ueno.jpg',
            resume: [
                '音研21発足以来、総務全般を担当しています。練習や公演の場所確保にいつも飛び回っております。',
                '会計が赤字にならないようにしながら毎年オペラ公演を行うのも一苦労です。',
                'お客様に喜んで頂けるような「音研21ならでは」の公演を目指し、これからも頑張っていきたいと思います。',
            ]
        }
    },
    {
        profile: {
            name_jp: '柏木 万理',
            name_en: 'Mari Kashiwagi',
            part: 'Soprano',
            photoImagePath: 'old_contents/images/mari.jpg',
            resume: [
                'ピアノも声楽も集中力を必要としますので、体調を整えながら勉強したいと思います。',
                '現在、声つくりに試行錯誤してます。',
                '今になって勉強できるチャンスを与えられ、大切にと思いつつもなかなかはかどりません。でも、目的に向かって歩んで行きたいです。',
            ]
        }
    },
    {
        profile: {
            name_jp: '杉田 優子',
            name_en: 'Yuko Sugita',
            part: 'Soprano',
            photoImagePath: 'old_contents/images/yuko.jpg',
            resume: [
                '小さい頃ピアノを習ってはいたものの、改めて音楽を始めたのは30歳を過ぎてから。それもあっという間に10年ほど過ぎ去ってしまいました。',
                '声は未だ細く頼りなげですが、高音を武器（？）にすべく、練習しています。',
            ]
        }
    },
    {
        profile: {
            name_jp: '塚田 頼子',
            name_en: 'Yoriko Tsukada',
            part: 'Soprano',
            photoImagePath: 'old_contents/images/kuma.gif',
            resume: [
                '　',
            ]
        }
    },
    {
        profile: {
            name_jp: '瑞穂',
            name_en: 'Mizuho',
            part: 'Mezzo Soprano',
            photoImagePath: 'old_contents/images/mizuho.jpg',
            resume: [
                '主婦です。',
                '人生の折返し点(？)を過ぎ、夢のような音楽の世界に、軽い気持ちで踏み込みましたが･････',
                '実際は、その難しさと奥の深さにたじろぎつつも･････、練習に励んでいます。',
            ]
        }
    },
    {
        profile: {
            name_jp: '高田 聖香',
            name_en: 'Kiyoka Takada',
            part: 'Mezzo Soprano',
            photoImagePath: 'old_contents/images/kiyoka.jpg',
            resume: [
                '第１回公演からフル出場しています。',
                '初めはオペラの楽譜を読むのも一苦労でした。',
                '今は、少しずつでも、前に出来なかったことが出来るようになることに小さな喜びを感じてずっと続けています。',
                'いくつになっても音研の舞台に立てるよう頑張っていきたいと思っています。',
            ]
        }
    },
    {
        profile: {
            name_jp: '矢作 倫子',
            name_en: 'Tomoko Yahagi',
            part: 'Mezzo Soprano',
            photoImagePath: 'old_contents/images/tomoko.jpg',
            resume: [
                '月曜日から金曜日まではホームヘルパーの仕事をしています。',
                'その合間にピアノと声楽を９年間やっています。',
                'やっとこさ指が動き、声がでてきました。１0年目をめざしてがんばるぞ！！！',
            ]
        }
    },
    {
        profile: {
            name_jp: '太田 輝基',
            name_en: 'Terumoto Ohta',
            part: 'Tenor',
            photoImagePath: 'old_contents/images/kouji.jpg',
            resume: [
                '在籍期間は長いのですが、まだまだ勉強しないといけないことがたくさんあるな、と痛感しています。',
                '練習時間がとりにくいので、少しずつ、こつこつと頑張っていきたいと思います。',
            ]
        }
    },
    {
        profile: {
            name_jp: '倉橋 光生',
            name_en: 'Kousei Kurahashi',
            part: 'Tenor',
            photoImagePath: 'old_contents/images/koichi.jpg',
            resume: [
                '子供に携わる仕事をしています。',
                '親子共々音研でお世話になっています。',
                '趣味は愛犬（ベル）を連れての散歩です。',
                '僕の甘い（！？）声でお客様を魅了できるようこれからも努力していきます。',
                '「歌」って素敵ですね。',
            ]
        }
    },
    {
        profile: {
            name_jp: '佐藤 龍司',
            name_en: 'Ryuji Sato',
            part: 'Tenor',
            photoImagePath: 'old_contents/images/tatsuo.jpg',
            resume: [
                'パートは、いちおうテノールです。（ときどきバス、ところによりバリトン？）',
                '日夜、仕事でもあり趣味でもあるコンピュータと戯れる傍ら、また最近は様々な植物の栽培にいそしみつつ、音楽を勉強しております。実は音研21に入る前はオペラに興味は無かったのですが、回を重ねる毎に面白さと奥の深さが少しずつ判ってきたような気がします。ピアノも音研21に入ってから習い始めました。ショパンが弾けるようになるのはいつの事やら…。',
                'お気に入りの歌手はドミンゴ、作曲家はプッチーニです。他にはショパン、モーツァルト、バッハも好きで、今一押しのピアニストは、シプリアン・カツァリスです。',
            ]
        }
    },
    {
        profile: {
            name_jp: '高橋 輝浩',
            name_en: 'Teruhiro Takahashi',
            part: 'Tenor',
            photoImagePath: 'old_contents/images/hirokazu.jpg',
            resume: [
                '事務系サラリーマンをしております。',
                '音研に入ってすでに１０年以上経ち、その間、会社は転職したりしましたが、なぜか音研21の活動はずっと続けています。生来、ひとつのことを続けていくことが難しい性質なので、このことには自分も驚いています。',
            ]
        }
    },
    {
        profile: {
            name_jp: '柳澤 輝明',
            name_en: 'Teruaki Yanagisawa',
            part: 'Tenor',
            photoImagePath: 'old_contents/images/teru.jpg',
            resume: [
                '現在医療器具の製造の仕事についている会社員です。。',
                '働きながら音楽の練習をするのは本当に大変ですが、頑張れるだけ頑張っているつもりです。',
            ]
        }
    },
    {
        profile: {
            name_jp: '横田 輝海',
            name_en: 'Terumi Yokota',
            part: 'Tenor',
            photoImagePath: 'old_contents/images/manabu.jpg',
            resume: [
                '私は、音研２１で音楽を勉強するようになってもう１０年以上になりますが、なかなか上達しないのが実情です。',
                'でもがんばっていこうと思っています。',
            ]
        }
    },
    {
        profile: {
            name_jp: '木下 誠',
            name_en: 'Makoto Kinoshita',
            part: 'Tenor',
            photoImagePath: 'old_contents/images/mitsugu.jpg',
            resume: [
                'あこがれのポンス、パヴァロッティ！太ったら彼らみたいな声になるかと思いましたが、ダメでした。',
                '今度は口髭でも伸ばしてみようか！．．．それより "練習しろ！" との事です。',
            ]
        }
    },
]

