import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from "@mui/material/Divider";

function Copyright() {

    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright 2020 - ' + new Date().getFullYear() + ' '}
            <Link color="inherit" href="https://www.onken21.net/">
                一般社団法人 ONKEN21
            </Link>
            {', All Rights Reserved.'}
            <br/>
            {'Copyright 2003 - 2020 音研21, All Rights Reserved.'}
            {'.'}
        </Typography>
    );
}

export default function Onken21Footer() {

    return (
        <Box component="footer" sx={{py: 0}}>
            <Divider/>
            <Container maxWidth="lg" sx={{pt: 2}}>
                <Copyright />
            </Container>
        </Box>
    );
}
