import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Onken21Header from "./Onken21Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import {globalTheme} from "./App";

type MyFunctionType = (page: number) => void;

interface AppHeaderProps {
    sections: ReadonlyArray<{
        title: string;
        url: string;
    }>;
    onDrawerToggle: () => void;
    onNavigatorClick: MyFunctionType;
}

export default function AppHeader(props: AppHeaderProps) {
    const {sections, onDrawerToggle, onNavigatorClick} = props;
    const isSmUp = useMediaQuery(globalTheme.breakpoints.up('md'));

    const onClickHeaderTab = (pageNumber: number) => {
        //console.log(`AppHeader:onClickHeaderTab pageNumber=${pageNumber}`);
        onNavigatorClick(pageNumber);
    }

    return (
        <React.Fragment>

            <AppBar color="primary" position="sticky" elevation={1}>

                <Toolbar sx={{bgcolor: 'white' /*'#eaeff1'*/}}>
                    <Grid container spacing={1} alignItems="center">

                        <Grid sx={{display: {sm: 'block', xs: 'block'}}} item>
                            {isSmUp ? null :
                                <IconButton
                                    color="primary"
                                    aria-label="open drawer"
                                    onClick={onDrawerToggle}
                                    edge="start"
                                >
                                    <MenuIcon/>
                                </IconButton>
                            }
                            {isSmUp ? <Onken21Header sections={sections} onNavigatorClick={onClickHeaderTab}/> : null}
                        </Grid>

                        <Grid item xs/>
                    </Grid>
                </Toolbar>
            </AppBar>

        </React.Fragment>
    );
}
