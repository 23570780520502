import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Dialog, {DialogProps} from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import {EventConcertProps} from "./data_event/EventConcertProps";
import {getPhoto} from "./data_photo/onken21_photo_detail";
import {globalTheme} from "./App";

interface TitleProps {
    children?: React.ReactNode;
}

export function Title(props: TitleProps) {
    return (
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {props.children}
        </Typography>
    );
}

const titleStyle = {color: "#007200", fontWeight: 'bold'};
const noteStyle = {color: "#ff69b4", fontWeight: 'bold'};
const notLineBreakStyle = {display: 'inline-block'};
let flyerPath = (flyer___file: string) => {
    return './old_contents/flyers/' + flyer___file;
}

export default function Onken21ConcertEvent(props: EventConcertProps) {
    const {
        event_id, event_date, event_time1, event_time2, event_place, event_station,
        composer_name, work_title, number_of_act, flyer_large_file, flyer_small_file,
        cast, ext_links
    } = props;
    //console.log(`Onken21ConcertEvent: event_id=${event_id}`);
    const [expanded1, setExpanded1] = React.useState<string | false>('panel1');
    const [expanded2, setExpanded2] = React.useState<string | false>('panel2');
    const [expanded3, setExpanded3] = React.useState<string | false>('panel3');
    const [open, setOpen] = React.useState(false);
    const [fullWidth, /*setFullWidth*/] = React.useState(true);
    const [maxWidth, /*setMaxWidth*/] = React.useState<DialogProps['maxWidth']>('lg');
    const [windowDimension, /*detectHW*/] = React.useState({
        winWidth: window.innerWidth, winHeight: window.innerHeight,
    })
    //console.log(`windowDimension: ${windowDimension.winWidth}, ${windowDimension.winHeight}`);
    const imageMaxHeight = windowDimension.winHeight * 0.75;
    //const fullScreen = useMediaQuery(globalTheme.breakpoints.down('md'));
    const xs_matches = useMediaQuery(globalTheme.breakpoints.up('xs'));
    const sm_matches = useMediaQuery(globalTheme.breakpoints.up('sm'));
    const md_matches = useMediaQuery(globalTheme.breakpoints.up('md'));
    const photoColumn = (xs_matches && sm_matches && md_matches) ? 4
        : (xs_matches && sm_matches && !md_matches) ? 2 : 1;
    //console.log(`photoColumn: ${photoColumn}, xs_matches: ${xs_matches}, xs_matches: ${sm_matches}, md_matches: ${md_matches}`);

    let photoSet = getPhoto(event_id);
    const photoImagePath = `./old_contents/images_event/${event_id}`;
    const isNoFlyerImage = (flyer_small_file.length === 0 || flyer_large_file.length === 0);
    const isNoPhoto = (photoSet.photo.length === 0);
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            if (panel === 'panel1') {
                setExpanded1(newExpanded ? panel : false);
            } else if (panel === 'panel2') {
                setExpanded2(newExpanded ? panel : false);
            } else {
                setExpanded3(newExpanded ? panel : false);
            }
        };
    const onClickEndHandler = () => {
        if (!isNoFlyerImage) {
            handleClickOpen();
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Paper sx={{backgroundColor: 'white'}}>
            <Stack spacing={2}>
                <Grid container sx={{mt: 5}}>
                    <Grid item xs={12} sm={12} md={12} sx={{m: 4}}>
                        <Typography variant="subtitle1" color="text.secondary">{composer_name}</Typography>
                        <div></div>
                        <Typography component="h2" variant="h5" /*style={notLineBreakStyle}*/>{work_title}</Typography>
                        <Typography variant="subtitle1" /*style={notLineBreakStyle}*/>{number_of_act}</Typography>
                        <div></div>
                        <Typography variant="subtitle1" /*paragraph*/ /*style={notLineBreakStyle}*/>{event_date}</Typography>
                        <Typography variant="subtitle2" paragraph style={notLineBreakStyle}>{event_time1}</Typography>
                        <Typography variant="subtitle2" paragraph style={notLineBreakStyle}>（{event_time2}）</Typography>
                        <div></div>
                        <Typography variant="subtitle1" /*paragraph*/ /*style={notLineBreakStyle}*/>{event_place}</Typography>
                        <Typography variant="subtitle2" paragraph /*style={notLineBreakStyle}*/>（{event_station}）</Typography>
                        <div></div>
                        <CardActionArea component="a" href="#" onClick={onClickEndHandler} sx={{maxWidth: 120}}>
                            <Card sx={{display: 'flex', borderRadius: 0}}>
                                <Box sx={{margin: 'auto'}}>
                                    <CardMedia
                                        component="img"
                                        sx={{boxShadow: 5}}
                                        image={flyerPath(flyer_small_file)}
                                        alt={'(no image)'}
                                    />
                                </Box>
                            </Card>
                        </CardActionArea>
                    </Grid>
                </Grid>

                <Grid container sx={{mt: 5}}>
                    {/* スタッフ */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Accordion expanded={expanded1 === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<AudiotrackIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Title><span style={titleStyle}><img
                                    src={"./old_contents/heart.gif"} alt={''}/> 出演</span></Title>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Table size="small">
                                    <TableBody>
                                        {cast.map((row, index) => {
                                            let hasAltName = (row.alt_name != null) && (row.alt_name.length > 0);
                                            let span = (row.title.length === 0) ? (<span/>) : (
                                                <span style={noteStyle}>♪ </span>);
                                            return (
                                                <TableRow key={row.name + `${index}`}>
                                                    <TableCell sx={{minWidth: 130}}>{span}{row.title}</TableCell>
                                                    {hasAltName
                                                        ? (<TableCell sx={{minWidth: 130}}><span>{row.name}<br/>{row.alt_name}</span></TableCell>)
                                                        : (<TableCell>{row.name}</TableCell>)
                                                    }
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    {/* 外部リンク */}
                    {
                        (ext_links.length != 0) ?
                            <Grid item xs={12} sm={12} md={12}>
                                <Accordion expanded={expanded2 === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<AudiotrackIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Title><span style={titleStyle}><img
                                            src={"./old_contents/heart.gif"} alt={''}/> 外部リンク</span></Title>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Table size="small">
                                            <TableBody>
                                                {ext_links.map((row, index) => {
                                                    //console.log(`⭐️ Onken21ConcertEvent: link_embedded_url=${row.link_embedded_url}`);
                                                    let width = sm_matches ? row.link_embedded_width * 2 : row.link_embedded_width;
                                                    let height = sm_matches ? row.link_embedded_height * 2 : row.link_embedded_height;
                                                    return (
                                                        <TableRow key={row.link_title + `${index}`}>
                                                            <TableCell sx={{minWidth: 130}}>
                                                                <iframe src={row.link_embedded_url} width={width} height={height}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid> : <div/>
                    }
                    {/* フォト */}
                    <Grid item xs={12} sm={12} md={12}>

                        <Accordion expanded={expanded3 === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<AudiotrackIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Title><span style={titleStyle}><img src={"./old_contents/heart.gif"} alt={''}/> フォト</span></Title>
                            </AccordionSummary>

                            <AccordionDetails>
                                {isNoPhoto
                                    ? (<Typography variant="subtitle1" paragraph sx={{pl: 4}}>(not available)</Typography>)
                                    : (<ImageList cols={photoColumn} rowHeight={'auto'}>
                                            {photoSet.photo.map((item) => (
                                                <ImageListItem key={item.src}>
                                                    <img
                                                        src={`${photoImagePath}/${item.src}`}
                                                        srcSet={`${photoImagePath}/${item.src}`}
                                                        alt={item.caption}
                                                        loading="lazy"
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    )
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    {/* large image modal dialog */}
                    <div>
                        <Dialog
                            //fullScreen={fullScreen}
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogContent sx={{p: 1}}>
                                <Box sx={{margin: 'auto', pr: 1, width: 'fit-content'}}>
                                    {/* iPhone 12 Pro Max=620, iPhone7=420 */}
                                    <img
                                        style={{maxHeight: imageMaxHeight/*425*/}}
                                        src={flyerPath(flyer_large_file)}
                                        alt={'no image'}
                                        loading="lazy"
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions sx={{p: 0}}>
                                <Button autoFocus onClick={handleClose}>閉じる</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Grid>
            </Stack>
        </Paper>
    );
}
