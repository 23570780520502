import 'reflect-metadata';
import { plainToClass } from 'class-transformer';
import {EventOperaProps} from "./EventOperaProps";
import {EventConcertProps} from "./EventConcertProps";

import opera_20190427_Json from './20190427_opera.json';
import opera_20180414_Json from './20180414_opera.json';
import opera_20170513_Json from './20170513_opera.json';
import opera_20170521_Json from './20170521_opera.json';
import opera_20150411_Json from './20150411_opera.json';
import opera_20150418_Json from './20150418_opera.json';
import opera_20140412_Json from './20140412_opera.json';
import opera_20140419_Json from './20140419_opera.json';
import opera_20120318_Json from './20120318_opera.json';
import opera_20120324_Json from './20120324_opera.json';
import opera_20100424_Json from './20100424_opera.json';
import opera_20100503_Json from './20100503_opera.json';
import opera_20090418_Json from './20090418_opera.json';
import opera_20090425_Json from './20090425_opera.json';
import opera_20080412_Json from './20080412_opera.json';
import opera_20080426_Json from './20080426_opera.json';
import opera_20060415_Json from './20060415_opera.json';
import opera_20060408_Json from './20060408_opera.json';
import opera_20050320a_Json from './20050320a_opera.json';
import opera_20050320b_Json from './20050320b_opera.json';
import opera_20050326a_Json from './20050326a_opera.json';
import opera_20050326b_Json from './20050326b_opera.json';
import opera_20040425_Json from './20040425_opera.json';
import opera_20040418_Json from './20040418_opera.json';
import opera_20030406_Json from './20030406_opera.json';
import opera_20030412_Json from './20030412_opera.json';
import opera_20020317_Json from './20020317_opera.json';
import opera_20010325_Json from './20010325_opera.json';
import opera_20000326_Json from './20000326_opera.json';
import opera_19990322_Json from './19990322_opera.json';
import opera_19970504_Json from './19970504_opera.json';
import opera_19960505_Json from './19960505_opera.json';
import opera_19950429_Json from './19950429_opera.json';
import opera_19940410_Json from './19940410_opera.json';
import opera_19920112_Json from './19920112_opera.json';
import opera_19910609_Json from './19910609_opera.json';
import opera_19900311_Json from './19900311_opera.json';
import opera_19890514_Json from './19890514_opera.json';
import opera_19880429_Json from './19880429_opera.json';

import joyful_20250119_Json from './20250119_joyful.json';
import joyful_20241102_Json from './20241102_joyful.json';
import joyful_20231112_Json from './20231112_joyful.json';
import joyful_20230923_Json from './20230923_joyful.json';
import joyful_20220910_Json from './20220910_joyful.json';
import joyful_20220416_Json from './20220416_joyful.json';
import joyful_20191026_Json from './20191026_joyful.json';
import joyful_20171029_Json from './20171029_joyful.json';
import joyful_20161029_Json from './20161029_joyful.json';
import spring_20130317_Json from './20130317_spring.json';
import joyful_20111029_Json from './20111029_joyful.json';
import joyful_20171007_Json from './20071007_joyful.json';
import joyful_20061009_Json from './20061009_joyful.json';
import joyful_20051022_Json from './20051022_joyful.json';
import joyful_20041011_Json from './20041011_joyful.json';
import joyful_20031018_Json from './20031018_joyful.json';
import regular_19891015_Json from './19891015_regular.json';
import regular_19901007_Json from './19901007_regular.json';
import regular_19910203_Json from './19910203_regular.json';
import regular_19930110_Json from './19930110_regular.json';

const jsonFileDictionary1: { [key: string]: EventOperaProps } = {
    '20190427': opera_20190427_Json,
    '20180414': opera_20180414_Json,
    '20170513': opera_20170513_Json,
    '20170521': opera_20170521_Json,
    '20150411': opera_20150411_Json,
    '20150418': opera_20150418_Json,
    '20140412': opera_20140412_Json,
    '20140419': opera_20140419_Json,
    '20120318': opera_20120318_Json,
    '20120324': opera_20120324_Json,
    '20100424': opera_20100424_Json,
    '20100503': opera_20100503_Json,
    '20090418': opera_20090418_Json,
    '20090425': opera_20090425_Json,
    '20080412': opera_20080412_Json,
    '20080426': opera_20080426_Json,
    '20060415': opera_20060415_Json,
    '20060408': opera_20060408_Json,
    '20050320a': opera_20050320a_Json,
    '20050320b': opera_20050320b_Json,
    '20050326a': opera_20050326a_Json,
    '20050326b': opera_20050326b_Json,
    '20040425': opera_20040425_Json,
    '20040418': opera_20040418_Json,
    '20030406': opera_20030406_Json,
    '20030412': opera_20030412_Json,
    '20020317': opera_20020317_Json,
    '20010325': opera_20010325_Json,
    '20000326': opera_20000326_Json,
    '19990322': opera_19990322_Json,
    '19970504': opera_19970504_Json,
    '19960505': opera_19960505_Json,
    '19950429': opera_19950429_Json,
    '19940410': opera_19940410_Json,
    '19920112': opera_19920112_Json,
    '19910609': opera_19910609_Json,
    '19900311': opera_19900311_Json,
    '19890514': opera_19890514_Json,
    '19880429': opera_19880429_Json
}
const jsonFileDictionary2: { [key: string]: EventConcertProps } = {
    '20250119': joyful_20250119_Json,
    '20241102': joyful_20241102_Json,
    '20231112': joyful_20231112_Json,
    '20230923': joyful_20230923_Json,
    '20220910': joyful_20220910_Json,
    '20220416': joyful_20220416_Json,
    '20191026': joyful_20191026_Json,
    '20171029': joyful_20171029_Json,
    '20161029': joyful_20161029_Json,
    '20130317': spring_20130317_Json,
    '20111029': joyful_20111029_Json,
    '20071007': joyful_20171007_Json,
    '20061009': joyful_20061009_Json,
    '20051022': joyful_20051022_Json,
    '20041011': joyful_20041011_Json,
    '20031018': joyful_20031018_Json,
    '19891015': regular_19891015_Json,
    '19901007': regular_19901007_Json,
    '19910203': regular_19910203_Json,
    '19930110': regular_19930110_Json,
}

export const getOpera = (event_id: string): EventOperaProps | undefined => {
    let jsonOperaObject = jsonFileDictionary1[event_id];
    if (jsonOperaObject) {
        let opera = plainToClass(EventOperaProps, jsonOperaObject);
        return opera;
    }
    return undefined;
}
export const getConcert = (event_id: string): EventConcertProps | undefined => {
    let jsonConcertObject = jsonFileDictionary2[event_id];
    if (jsonConcertObject) {
        let concert = plainToClass(EventOperaProps, jsonConcertObject);
        return concert;
    }
    return undefined;
}




