import 'reflect-metadata';
import { plainToClass } from 'class-transformer';
import { EventPhotoProps } from "./EventPhotoProps";

import photo_19880429_Json from './19880429_photo.json';
import photo_19890514_Json from './19890514_photo.json';
import photo_19900311_Json from './19900311_photo.json';
import photo_19920112_Json from './19920112_photo.json';
import photo_19930110_Json from './19930110_photo.json';
import photo_19940410_Json from './19940410_photo.json';
import photo_19960505_Json from './19960505_photo.json';
import photo_19970504_Json from './19970504_photo.json';
import photo_19990322_Json from './19990322_photo.json';
import photo_20000326_Json from './20000326_photo.json';
import photo_20010325_Json from './20010325_photo.json';
import photo_20020317_Json from './20020317_photo.json';
import photo_20031018_Json from './20031018_photo.json';
import photo_20041011_Json from './20041011_photo.json';
import photo_20030406_Json from './20030406_photo.json';
import photo_20030412_Json from './20030412_photo.json';
import photo_20040418_Json from './20040418_photo.json';
import photo_20040425_Json from './20040425_photo.json';
import photo_20060408_Json from './20060408_photo.json';
import photo_20060415_Json from './20060415_photo.json';
import photo_20090418_Json from './20090418_photo.json';
import photo_20090425_Json from './20090425_photo.json';
import photo_20100503_Json from './20100503_photo.json';
import photo_20100424_Json from './20100424_photo.json';
import photo_20120318_Json from './20120318_photo.json';
import photo_20120324_Json from './20120324_photo.json';
import photo_20140412_Json from './20140412_photo.json';
import photo_20150411_Json from './20150411_photo.json';
import photo_20170513_Json from './20170513_photo.json';
import photo_20170521_Json from './20170521_photo.json';
import photo_20180414_Json from './20180414_photo.json';
import photo_20190427_Json from './20190427_photo.json';
import photo_20220416_Json from './20220416_photo.json';
import photo_20220910_Json from './20220910_photo.json';
import photo_20230923_Json from './20230923_photo.json';

const jsonFileDictionary: { [key: string]: EventPhotoProps } = {
    '19880429': photo_19880429_Json,
    '19890514': photo_19890514_Json,
    '19900311': photo_19900311_Json,
    '19920112': photo_19920112_Json,
    '19930110': photo_19930110_Json,
    '19940410': photo_19940410_Json,
    '19960505': photo_19960505_Json,
    '19970504': photo_19970504_Json,
    '19990322': photo_19990322_Json,
    '20000326': photo_20000326_Json,
    '20010325': photo_20010325_Json,
    '20020317': photo_20020317_Json,
    '20031018': photo_20031018_Json,
    '20041011': photo_20041011_Json,
    '20030406': photo_20030406_Json,
    '20030412': photo_20030412_Json,
    '20060408': photo_20060408_Json,
    '20060415': photo_20060415_Json,
    '20040418': photo_20040418_Json,
    '20040425': photo_20040425_Json,
    '20090418': photo_20090418_Json,
    '20090425': photo_20090425_Json,
    '20100503': photo_20100503_Json,
    '20100424': photo_20100424_Json,
    '20120318': photo_20120318_Json,
    '20120324': photo_20120324_Json,
    '20140412': photo_20140412_Json,
    '20150411': photo_20150411_Json,
    '20170513': photo_20170513_Json,
    '20170521': photo_20170521_Json,
    '20180414': photo_20180414_Json,
    '20190427': photo_20190427_Json,
    '20220416': photo_20220416_Json,
    '20220910': photo_20220910_Json,
    '20230923': photo_20230923_Json,
}

export const getPhoto = (event_id: string): EventPhotoProps => {
    let jsonOperaObject = jsonFileDictionary[event_id];
    //console.log(`getPhoto = ${JSON.stringify(jsonOperaObject)}`);
    if (jsonOperaObject) {
        let photo = plainToClass(EventPhotoProps, jsonOperaObject);
        return photo;
    }
    return new EventPhotoProps(event_id, '', '', '', []);
}

