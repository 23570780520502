import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

import {our_mission, TeacherProfile} from './data/profile_teacher';
import {yoshida, higashijima, matsuda, yamada} from './data/profile_teacher';
import {watanabe} from './data/profile_teacher';

function Onken21Teacher(props: TeacherProfile) {
    const {profile} = props;
    ///console.log(`Onken21Teacher: ${profile.photoImagePath}`);
    return (

        <Card sx={{borderRadius: 1, justifyContent: "center", display: "flex"}}>

            <Grid container sx={{backgroundColor: 'rgba(245,245,245,255)'}}>

                <Grid item xs={12} sm={4} md={4} sx={{m: 0 /*display: 'flex', flexDirection: 'row', minHeight: 350*/}}>

                    <Card sx={{width: 250, borderRadius: 1, justifyContent: "center", display: "flex"}}>
                        <img width={250} src={profile.photoImagePath} />
                    </Card>

                </Grid>

                <Grid item xs={12} sm={3} md={3} sx={{m: 2}}>
                    <Typography sx={{fontSize: 16, ml: 0.5}} color="text.secondary" gutterBottom>
                        {profile.name_en}
                    </Typography>
                    <Typography variant="h4" component="div">
                        {profile.name_jp}
                    </Typography>
                    <Typography sx={{fontsize: 16, ml: 0.5, mb: 2}} color="text.secondary">
                        {profile.part}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4} sx={{m: 2}}>
                    {profile.resume.map((element, index) => (
                        <Typography variant="body1" key={`${index}`}>
                            {element}
                        </Typography>
                    ))}
                </Grid>

            </Grid>
        </Card>

    );
}

const profile0 = [higashijima.profile, matsuda.profile, yamada.profile];
const profile1 = [matsuda.profile, yamada.profile, higashijima.profile];
const profile2 = [yamada.profile, higashijima.profile, matsuda.profile];

export default function Onken21About() {

    const today = new Date();
    const epochSeconds = Math.floor(today.getTime() / 1000);
    const epochDate = Math.trunc(epochSeconds / (3600 * 24));
    const roulette = epochDate % 3;
    //console.log(`roulette = ${roulette}`);
    const final_profile = roulette === 0 ? profile0 : (roulette === 1 ? profile1 : profile2);

    return (
        <Grid container sx={{mt: 0, backgroundColor: 'rgba(0.5, 0.5, 0.5, 0)',}}>
            <Card sx={{minWidth: 400, width: '100%', backgroundColor: 'rgba(245,245,245,255)'}}>
                <CardContent>
                    {
                        our_mission.map((element, index) => (
                            <Grid item xs={12} key={`cc${index}`}>
                                <Typography color="text.primary">{element}</Typography>
                            </Grid>
                        ))
                    }
                </CardContent>
            </Card>

            {/** Teachers¥'s profile (fixed) **/}
            <Box sx={{width: '100%', mt: 4}}>
                <Onken21Teacher profile={yoshida.profile}/>
            </Box>

            {/** Teachers¥'s profile (variable) **/}
            {final_profile.map((element) => (
                <Box sx={{width: '100%', mt: 4}} key={element.name_jp}>
                    <Onken21Teacher profile={element}/>
                </Box>
            ))}
            {/** Teacher's profile (fixed) **/}

            <Box sx={{width: '100%', mt: 4, mb: 4}}>
                <Grid>
                    <img src={'old_contents/images/line_1.gif'}/>
                    <img src={'old_contents/images/line_2.gif'}/>
                    <img src={'old_contents/images/line_3.gif'}/>
                </Grid>
            </Box>

            <Box sx={{width: '100%'}}>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<AudiotrackIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{fontSize: 14, mt: 1}} color="text.secondary" gutterBottom>
                            {watanabe.profile.name_jp} {watanabe.profile.name_en}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Onken21Teacher profile={watanabe.profile}/>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Grid>
    );
}
