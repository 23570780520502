import React, { useState } from 'react'
//import {Container, Grid as GridMUI } from '@mui/material';
import Box from '@mui/material/Box';
//import Paper from '@mui/material/Paper';
import {getPhoto} from "./data_photo/onken21_photo_detail";
//import {EventPhotoProps, PhotoInfo} from "./data_photo/EventPhotoProps";
//import CardMedia from "@mui/material/CardMedia";

import './App.css';
import Carousel from 'nuka-carousel';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu";
import Button from "@mui/material/Button";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {EventType, past_events} from "./data/onken21_history_index";

/*
function Item(props: PhotoInfo) {
    const {src, caption, description} = props;
    return (
        <Paper sx={{'object-fit': 'contain', maxWidth: 800}}>
            <GridMUI container spacing={1} alignItems="center">
                <GridMUI item>
                    <Box sx={{margin: 'auto', pr: 4}}>
                        <CardMedia
                            component="img"
                            sx={{boxShadow: 5, }}
                            image={props.src}
                            alt={''}
                        />
                    </Box>
                </GridMUI>
            </GridMUI>
        </Paper>
    )
}*/

function LeftArrow() {
    const {isFirstItemVisible, scrollPrev} = React.useContext(VisibilityContext);
    return (
        <Button disabled={isFirstItemVisible} sx={{height: 76}} onClick={() => scrollPrev()}>
            <ArrowCircleLeftIcon sx={{width: 50, height: 50}}/>
        </Button>
    );
}

function RightArrow() {
    const {isLastItemVisible, scrollNext} = React.useContext(VisibilityContext);
    return (
        <Button disabled={isLastItemVisible} sx={{height: 76}} onClick={() => scrollNext()}>
            <ArrowCircleRightIcon sx={{width: 50, height: 50}}/>
        </Button>
    );
}

const getColor = (eventType: EventType) => {
    if (eventType === EventType.Opera) {
        return '#F8FFF8'; //'lightcyan';
    } else if (eventType === EventType.Operetta) {
        return '#F8FFF8'; //'cornsilk';
    }
    return '#F8FFF8'; //'antiquewhite'; // Concert
};

const sidebar = past_events;
const initialEventId = past_events.archives[0].event_id;

export default function Onken21PhotoGallery() {
    const [selectedEventId, setSelectedEventId] = useState<string>(initialEventId);
    const [message, setMessage] = useState('');
    let photoSet = getPhoto(selectedEventId);
    const items = photoSet.photo;
    //const slideCount = items.length;
    const photoImagePath = `./old_contents/images_event/${selectedEventId}`;
    console.log(`items.length=${items.length}`)
    const handleItemClick = (event: React.MouseEvent<HTMLElement>, event_id: string) => {
        //console.log(`event_id = ${event_id}`);
        setSelectedEventId(event_id);
    };
    let photoArchives = sidebar.archives.filter((archive) => {
        let photoSet = getPhoto(archive.event_id);
        if (photoSet.photo.length > 0) {
            return archive.event_id;
        }
    });
    //console.log(`photoArchives = ${JSON.stringify(photoArchives)}`);
    photoArchives.map((archive) => {
        //console.log(`archive = ${JSON.stringify(archive.event_date)}`);
    });

    return (
        <Grid container sx={{mt: 0}}>
            <Grid item xs={12} sm={12} md={12} sx={{width: 350, height: 90, mt: 0, mb:0, backgroundColor: 'transparent'}}>
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                    {/*sidebar.archives*/photoArchives.map((archive) => {
                        return (
                            <Button
                                key={archive.event_id}
                                sx={{
                                    width: 236,
                                    height: 60,
                                    backgroundColor: getColor(archive.event_type),
                                    ml: 0.5, mt: 1, mr: 0.5, mb: 1,
                                    borderRadius: 2,
                                    boxShadow: 2
                                }}
                                onClick={(e) => handleItemClick(e, archive.event_id)}
                            >
                                <Typography variant="subtitle1">
                                    <Grid>
                                        <Grid>{archive.event_date}</Grid>
                                        <Grid>{archive.title}</Grid>
                                    </Grid>
                                </Typography>
                            </Button>
                        )
                    })}
                </ScrollMenu>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{}}>
                <Typography variant="h6" align="center" sx={{color: '#007200', fontWeight: 'bold'}}>
                    {photoSet.work_title}
                </Typography>
                <Carousel
                    className="carousel"
                    autoplay={true}
                    autoplayInterval={4000}
                    slidesToShow={1}
                    cellSpacing={0}
                    wrapAround={true}
                    defaultControlsConfig={{
                        nextButtonText: '＞',
                        prevButtonText: '＜',
                        nextButtonStyle: { color: '#00cc00', fontSize: 20, fontWeight: 'bold' },
                        prevButtonStyle: { color: '#00cc00', fontSize: 20, fontWeight: 'bold' },
                        pagingDotsStyle: { fill: '#FF000000', padding: 0, width: 0, height: 0},
                    }}
                    beforeSlide={(index) => {
                        //console.log(`⭐️ afterSlide ${index}`);
                        /*if (index >= photoArchives.length) {
                            setSelectedEventId(selectedEventId);
                        }*/
                    }}
                    afterSlide={(index) => {
                        //console.log(`⭐️ afterSlide ${index}`);
                    }}
                    renderAnnounceSlideMessage={({ currentSlide, count}) => {
                        if (currentSlide < photoSet.photo.length) {
                            let caption = photoSet.photo[currentSlide].caption;
                            setMessage(caption);
                            //console.log(`⭐️ caption ${caption}`);
                            return caption;
                        } else {
                            return '';
                        }
                    }}
                >
                    {items.map((element, index) => (
                        <img
                            className="photo"
                            key={`${index}`}
                            src={`${photoImagePath}/${element.src}`}
                            alt=""
                        />
                    ))}
                </Carousel>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Box
                    sx={{paddingTop: '0.5em', paddingBottom: '0.5em'}}
                >
                    <Typography variant="h6" align="center" sx={{color: '#007200', fontWeight: 'bold'}}>
                        {message}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}

/*
export default function Onken21PhotoGallery() {
    const event_id = '20120318';
    let photoSet = getPhoto(event_id);
    const items = photoSet.photo;
    const photoImagePath = `./old_contents/images_event/${event_id}`;
    console.log(`items.length=${items.length}`)

    return (
        <Grid container sx={{mt: 0}}>
            <Grid item xs={12} sm={12} md={12} sx={{width: '100%'}}>
                <Carousel
                    autoplay={true}
                    autoplayInterval={4000}
                    wrapAround={true}
                    slidesToShow={2}
                    cellSpacing={20}
                    defaultControlsConfig={{
                        nextButtonText: 'Next',
                        prevButtonText: 'Prev',
                        pagingDotsStyle: {
                            fill: 'greenyellow'
                        }
                    }}
                    className="carousel"
                >
                    <img className="photo" src={`${photoImagePath}/${items[0].src}`} />
                    <img className="photo" src={`${photoImagePath}/${items[1].src}`} />
                    <img className="photo" src={`${photoImagePath}/${items[2].src}`} />
                    <img className="photo" src={`${photoImagePath}/${items[3].src}`} />
                    <img className="photo" src={`${photoImagePath}/${items[4].src}`} />
                    <img className="photo" src={`${photoImagePath}/${items[5].src}`} />
                    <img className="photo" src={`${photoImagePath}/${items[6].src}`} />
                    <img className="photo" src={`${photoImagePath}/${items[7].src}`} />
                    <img className="photo" src={`${photoImagePath}/${items[8].src}`} />

                </Carousel>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            </Grid>
        </Grid>
    );
}
 */