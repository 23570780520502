import './App.css';
import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';

//import Onken21Header from './Onken21Header';
import Onken21Home from './Onken21Home';
import Onken21Footer from './Onken21Footer';
import Onken21About from './Onken21About';
import Onken21AllMember from './Onken21Member';
import Onken21History from './Onken21History';

import AppNavigator from './AppNavigator';
import AppHeader from './AppHeader';
import {DrawerProps} from "@mui/material/Drawer";
import Onken21MessageBoard from "./Onken21MessageBoard";
import Onken21PhotoGallery from "./Onken21PhotoGallery";

export let globalTheme = createTheme({
    palette: {primary: {light: '#00cc00', main: '#007200', dark: '#00cc00'}},
    typography: {h5: {fontWeight: 500, fontSize: 26, letterSpacing: 0.5}},
    shape: {borderRadius: 8},
    components: {MuiTab: {defaultProps: {disableRipple: true}}},
    mixins: {toolbar: {minHeight: 48}},
    breakpoints: {values: {xs: 0, sm: 600, md: 1100/*900*/ , lg: 1200, xl: 1536} },
});

globalTheme = {
    ...globalTheme,
    components: {
        MuiDrawer: {
            //styleOverrides: {paper: {backgroundColor: '#081627'}},
            styleOverrides: {paper: {backgroundColor: '#eaeff1' /*'#005500'*/ /*'#eaeff1'*/}},
        },
        MuiButton: {
            styleOverrides: {root: {textTransform: 'none'}, contained: {boxShadow: 'none', '&:active': {boxShadow: 'none'}}},
        },
        MuiTabs: {
            styleOverrides: {root: {marginLeft: globalTheme.spacing(1)}, indicator: {height: 3, borderTopLeftRadius: 3, borderTopRightRadius: 3, backgroundColor: globalTheme.palette.common.white}},
        },
        MuiTab: {
            styleOverrides: {root: {textTransform: 'none', margin: '0 16px', minWidth: 0, padding: 0, [globalTheme.breakpoints.up('md')]: {padding: 0, minWidth: 0}}},
        },
        MuiIconButton: {
            styleOverrides: {root: {padding: globalTheme.spacing(1)}},
        },
        MuiTooltip: {
            styleOverrides: {tooltip: {borderRadius: 4}},
        },
        MuiDivider: {
            styleOverrides: {root: {backgroundColor: 'rgb(255,255,255,0.15)'}},
        },
        MuiListItemButton: {
            //styleOverrides: {root: {'&.Mui-selected': {color: '#4fc3f7'}}},
            styleOverrides: {root: {'&.Mui-selected': {color: '#00cc00'}}},
        },
        MuiListItemText: {
            styleOverrides: {primary: {fontSize: 14, fontWeight: globalTheme.typography.fontWeightMedium}},
        },
        MuiListItemIcon: {
            styleOverrides: {root: {color: 'inherit', minWidth: 'auto', marginRight: globalTheme.spacing(2), '& svg': {fontSize: 20}}},
        },
        MuiAvatar: {
            styleOverrides: {root: {width: 32, height: 32}},
        },
    },
};

const sections = [
    {title: 'ホーム', url: '/'},
    {title: '音研21の紹介', url: '/about'},
    {title: '会員の紹介', url: '/member'},
    {title: '今までの活動・公演', url: '/history'},
    {title: 'フォトギャラリー', url: '/photo'},
    {title: '掲示板', url: '/bbs'},
];

interface MainBodyState {
    page: number;
}

const MainBody = (state: MainBodyState) => {
    switch (state.page) {
        case 0:
            return (<Onken21Home/>);
        case 1:
            return (<Onken21About/>);
        case 2:
            return (<Onken21History/>);
        case 3:
            return (<Onken21AllMember/>);
        case 4:
            return (<Onken21PhotoGallery/>);
        case 5:
            return (<Onken21MessageBoard />);
    }
    return (<Onken21Home/>);
}

type MyFunctionType = (page: number) => void;

export interface AppDrawerProps extends DrawerProps {
    onNavigatorClick: MyFunctionType;
}

export default function App() {
    const [currentPage, setCurrentPage] = React.useState(0);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    //const isSmUp = useMediaQuery(globalTheme.breakpoints.up('sm'));
    const handleDrawerToggle = () => {
        //console.log(`App:handleDrawerToggle mobileOpen=${mobileOpen}`);
        setMobileOpen(!mobileOpen);
    };
    const onClickHeaderTab = (pageNumber: number) => {
        console.log(`App:onClickHeaderTab pageNumber=${pageNumber}`);
        if (mobileOpen) {
            setMobileOpen(!mobileOpen);
        }
        setCurrentPage(pageNumber);
    }
    const drawerWidth = 256;

    return (
        <ThemeProvider theme={globalTheme}>

            <Box sx={{width: '100vw'}}>
                <CssBaseline/>

                <Box
                    component="nav"
                    sx={{width: {/*sm: drawerWidth,*/ xs: 0, sm: 0, md: 0, lg: 0, xl: 0}, flexShrink: {sm: 0}}}
                >
                    {(
                        <AppNavigator
                            PaperProps={{style: {width: drawerWidth}}}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            onNavigatorClick={onClickHeaderTab}
                        />
                    )}

                </Box>

                {/* main contents */}

                <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>

                    <AppHeader sections={sections} onDrawerToggle={handleDrawerToggle} onNavigatorClick={onClickHeaderTab}/>

                    <Box component="main" sx={{flex: 1, py: 2, px: 2}}>
                        <MainBody page={currentPage}/>
                    </Box>

                    <Box component="footer" sx={{p: 2}}>
                        <Onken21Footer/>
                    </Box>
                </Box>

            </Box>
        </ThemeProvider>
    );
}





































