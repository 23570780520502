import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import Grid from '@mui/material/Grid';

const source = 'https://www.onken21.net/cgi-bin/simbbsex_p20211123.cgi';

export default function Onken21MessageBoard() {

    const ref = useRef(null);
    const [/*height*/, setHeight] = useState(0);
    const [/*width*/, setWidth] = useState(0);
    useEffect(() => {
        // @ts-ignore
        setHeight(ref.current.offsetHeight);
        // @ts-ignore
        setWidth(ref.current.offsetWidth);
        // 👇️ if you need access to parent
        // of the element on which you set the ref
        // @ts-ignore
        console.log(ref.current.parentElement);
        // @ts-ignore
        console.log(ref.current.parentElement.offsetHeight);
        // @ts-ignore
        console.log(ref.current.parentElement.offsetWidth);
    }, []);

    return (
        <Grid container sx={{height: '100vh', mt: 0, backgroundColor: 'rgba(0.5, 0.5, 0.5, 0)'}}  >
            <Grid item xs={12} sm={12} md={12} >
                <div ref={ref} style={{height: '100%'}}>
                    <iframe
                        src={source}
                        style={{ width: '100%', height: '100%'}}
                    />
                </div>
            </Grid>
        </Grid>
    );
}
