export interface TeacherProfile {
    profile: {
        name_jp: string,
        name_en: string,
        part: string,
        photoImagePath: string,
        resume: string[]
    };
}

export const yoshida: TeacherProfile = {
    profile: {
        name_jp: '吉田 光輝',
        name_en: 'Kouki Yoshida',
        part: 'Tenor',
        photoImagePath: 'old_contents/images/yoshida.jpg',
        resume: [
            '1926年　広島に生まれる。',
            '1944年　東京大学法学部入学。',
            '1945年　徴兵され戦争に参加。原爆で家族を失う。敗戦。復学。',
            '1949年　卒業。東京芸術大学声楽科入学。白血病で挫折。',
            '1953年　かろうじて卒業。奇跡的に病を克服、しかし長期間の空白。',
            '1988年　音研21を結成。若い人たちと楽しんだり、苦しんだりしながら活動中。',
            '2017年　8月4日 逝去',
        ]
    }
}

export const higashijima: TeacherProfile = {
    profile: {
        name_jp: '東島 のり子',
        name_en: 'Noriko Higashijima',
        part: 'Soprano',
        photoImagePath: 'old_contents/images/noriko.jpg',
        resume: [
            '東京生まれ',
            '幼い頃から音楽が好きで５歳からピアノを始める。父の転勤で転校を繰り返すがなんとか続ける。',
            '小中学校ではアコーディオン、小太鼓、アルトサックスなどを経験。',
            '日大二高でも吹奏楽部に入り、ホルンを担当。コンクールで全国大会出場も果たす。',
            '日本大学理工学部卒業後、某化粧品会社の研究室に勤める。',
            'エレクトーンを習い始め、ヤマハのグレードを取得。',
            'その後、吉田光輝氏に声楽を師事。',
            '国立音楽大学声楽科卒業。',
            '現在、音研21音楽教室でピアノ、声楽、ソルフェージュ等の指導をしながらオペラや歌のコンサート活動を続けている。',
        ]
    }
}

export const matsuda: TeacherProfile = {
    profile: {
        name_jp: '松田 輝忠',
        name_en: 'Terutada Matsuda',
        part: 'Baritone',
        photoImagePath: 'old_contents/images/terutada.jpg',
        resume: [
            '京都市生まれ',
            '早稲田大学商学部、東京藝術大学音楽学部声楽科卒業。',
            '社会人になって音研２１に出会うまでは、クラッシック音楽とは全く無縁の生活。',
            '音研２１の素晴らしい舞台でオペラの楽しさを知り、より専門的に勉強するため大学で声楽を学び、現在に至る。',
            '吉田光輝氏、福島明也氏に師事',
        ]
    }
}

export const yamada: TeacherProfile = {
    profile: {
        name_jp: '山田 光穗湖',
        name_en: 'Mihoko Yamada',
        part: 'Soprano',
        photoImagePath: 'old_contents/images/mihoko.jpg',
        resume: [
            '4歳の時から、クラシックバレエを習い始める。',
            '6歳の時から、ピアノをはじめる。',
            '小学校時代は、東京児童合唱団、歌劇団に所属する。',
            'その後、音楽は一時中断し、水泳、バスケットなど、スポーツに専念。',
            '二十歳を過ぎたころから、オペラに興味を持ち、吉田光輝氏に声楽を学ぶ。1987年音研21教師となる。',
            '武蔵野音楽大学、音楽学部、声楽学科卒業',
        ]
    }
}

export const watanabe: TeacherProfile = {
    profile: {
        name_jp: '渡辺 経子',
        name_en: 'Kyoko Watanabe',
        part: 'Soprano',
        photoImagePath: 'old_contents/images/kyoko.jpg',
        resume: [
            '広島生まれ',
            '音研21発足当時から長い間、オペラやコンサートで活躍してきた渡辺経子は、2012年4月21日に永眠致しました。',
            '今までたくさんのお客様に応援して頂き、歌声を聴いて頂いて誠にありがとうございました。',
        ]
    }
}

export const our_mission = [
    '私たち音研２１は、音楽を愛し音楽を真剣に追求する人達が集って1988年に結成されたグループです。',
    '現在、東京芸大、国立音大、武蔵野音大卒業の先生方によって指導を受け、技術と音楽性の向上に日々励んでおります。',
    '結成以来、毎年、下記の活動を行ってきました。今後も、より向上を目指して、精進していくつもりです。',
    '　',
    '・オペラ・オペレッタ公演',
    '・レギュラー・コンサート・・・・先生によるコンサート',
    '・オールメンバー・コンサート・・会員が日ごろの練習の成果を発表する公演',
]
