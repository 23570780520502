import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {MemberProfile} from "./data/profile_member";
import {allMember} from './data/profile_member';


function Onken21Member(props: MemberProfile) {
    const {profile} = props;
    ///console.log(`Onken21Teacher: ${profile.photoImagePath}`);
    return (

        <Grid container sx={{borderRadius: 1, backgroundColor: 'rgba(245,245,245,255)', mt: 2, mb: 2}}>
            <Grid sx={{display: 'flex', flexDirection: 'row', minHeight: 250}}>

                <Grid container sx={{width: 100, m: 2, height: 0, borderRadius: 10}}>
                    <img width={100} src={profile.photoImagePath}/>
                </Grid>

                <Grid sx={{m: 2}}>
                    <Typography sx={{fontSize: 15, ml: 0.3}} color="text.secondary" gutterBottom>
                        {profile.name_en}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {profile.name_jp}
                    </Typography>
                    <Typography sx={{fontsize: 10, ml: 0.3, mb: 2}} color="text.secondary">
                        {profile.part}
                    </Typography>
                    <Typography variant="body1">
                        {profile.resume.map((element: any, index: number) => (
                            <span key={index}>{element}<br/></span>
                        ))}
                    </Typography>
                </Grid>

            </Grid>

        </Grid>

    );
}

export default function Onken21AllMember() {

    return (
        <Grid container sx={{mt: 0}}>

            <Grid item xs={12} sm={12} md={12}>
                <Card sx={{minWidth: 400, width: '100%', backgroundColor: 'rgba(245,245,245,255)'}}>
                    <CardContent>
                        <Typography sx={{fontSize: 20, ml: 1, mt: 1, fontWeight: 'bold'}} color="text.secondary">
                            会員の紹介
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                {/** member's profile **/}
                <Box sx={{width: '100%', mt: 0}}>
                    {allMember.map((element) => (
                        <Onken21Member profile={element.profile} key={element.profile.name_jp}/>
                    ))}
                </Box>
            </Grid>

        </Grid>
    );
}
