import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useMediaQuery from "@mui/material/useMediaQuery";
import {globalTheme} from "./App";
import { HomeImageTitlePositionProps} from "./data/home_contents";

export default function MainFeaturedPost(props: HomeImageTitlePositionProps) {
    const {low_position} = props;

    const isSmUp = useMediaQuery(globalTheme.breakpoints.up('sm'));
    //const isXsUp = useMediaQuery(globalTheme.breakpoints.up('xs'));
    const fontSizeMain = isSmUp ? 60 : 28;
    const fontSizeSub = isSmUp ? 22 : 13;
    const mt = low_position ? (isSmUp ? 0 : 15) : 0;
    const height = (isSmUp ? 390 : 200) - mt * 8.0;
    const line_height_main = (isSmUp ? 66 : 38);

    return (
        <Grid item xs={12} sm={12} md={12} sx={{height: height}}>
            <Box sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)', mt: 0}}>

                <Typography variant="subtitle2" paragraph sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: fontSizeSub,
                    mt: mt,
                    mb: 0,
                    pt: 0,
                    pl: 2,
                    pb: 0,
                }}>
                    一般社団法人
                </Typography>
                <Typography variant="subtitle2" paragraph sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: fontSizeMain,
                    'line-height': line_height_main,
                    mt: 0,
                    mb: 0,
                    pt: 0,
                    pl: 2,
                    pb: 0,
                }}>
                    ONKEN21公式サイト
                </Typography>

            </Box>
        </Grid>
    );
}
