import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home'; // ホーム
import InfoIcon from '@mui/icons-material/Info'; // 音研21の紹介
import GroupIcon from '@mui/icons-material/Group'; // 会員紹介
import HistoryIcon from '@mui/icons-material/History'; // 今までの活動・公演
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'; // フォトギャラリー
import MessageIcon from '@mui/icons-material/Message'; // 掲示板
import {AppDrawerProps} from "./App";

const categories = [
  {
    id: 'Build',
    children: [
      { id: 0, title: 'ホーム', icon: <HomeIcon /> },
      { id: 1, title: '音研21の紹介', icon: <InfoIcon /> },
      { id: 2, title: '今までの活動・公演', icon: <HistoryIcon /> },
      { id: 3, title: '会員の紹介', icon: <GroupIcon /> },
      { id: 4, title: 'フォトギャラリー', icon: <PhotoLibraryIcon /> },
      { id: 5, title: '掲示板', icon: <MessageIcon /> },
    ],
  },
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(0, 85, 0, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(0, 85, 0, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function AppNavigator(props: AppDrawerProps) {
  const { onNavigatorClick, ...other  } = props;
  const [activePage, setActivePage] = React.useState(0);

  const onClickHandler = (childId: number) => {
    console.log(`childId=${childId}`);
    setActivePage(childId);
    onNavigatorClick(childId);
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>

        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#005500' }}>
          {/*一般社団法人ONKEN21公式サイト*/}
          <img width={200} src={"./old_contents/new_title.gif"}  alt={''}/>
        </ListItem>

        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#eaeff1' }}>
            {/*<ListItem sx={{ py: 2, px: 3 }}><ListItemText sx={{ color: '#fff' }}>{id}</ListItemText></ListItem>*/}
            {children.map(({ id: childId, title, icon }) => (
              <ListItem disablePadding key={childId} onClick={()=>onClickHandler(childId)}>
                <ListItemButton selected={activePage===childId} sx={item}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{title}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}

            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}

      </List>
    </Drawer>
  );
}
